<template>
  <!-- :class="['col-sm-3', 'd-flex p-2 std-border segment-tab query-tab']" -->
  <div class="row justify-content-center no-gutters">
    <router-link
      tag="div"
      :class="[
        isFIContactsFiltered && fisWithContactsCount ? 'col-sm-4' : 'col-sm-3',
        'd-flex p-2 std-border segment-tab query-tab'
      ]"
      to="/technographics/fin_query/query"
    >
      <div class="d-flex align-items-center">
        <div>
          <span class="pr-2">
            <i class="fa fa-university fi-bank-icon"></i>
          </span>
        </div>
        <div class="" v-if="fiCount !== null">
          <div v-if="fetchingFIs">
            <i class="fa fa-spinner fa-spin"></i>
          </div>
          <div v-else>
            <div v-if="isFIContactsFiltered" class="d-flex">
              <h4 class="mb-0">
                <b>{{ fiCount | numberFormat }}</b>
              </h4>
              <h6 class="mb-dot-1 text-muted font-italic pl-2 align-self-end">
                (<i
                  class="fa fa-spinner fa-spin px-1"
                  v-if="loadingContactCount"
                ></i
                ><b v-else>{{ fisWithContactsCount }}</b> FIs with Contacts)
              </h6>
            </div>
            <h4 class="mb-0" v-else>
              <b>{{ fiCount | numberFormat }}</b>
            </h4>
          </div>
          <h6 class="mb-0">
            Matching {{ fiCount | pluralize('Institution') }}
          </h6>
        </div>
        <div v-else>
          <div v-if="fetchingFIs">
            <i class="fa fa-spinner fa-spin"></i>
          </div>
          <div v-else>
            <h4 class="mb-0">
              <b> - </b>
            </h4>
          </div>

          <h6 class="mb-0">
            Matching Institutions
          </h6>
        </div>
      </div>
    </router-link>

    <router-link
      tag="div"
      class="col-sm-3 d-flex align-items-center p-2 segment-tab report-element-tab"
      to="/technographics/fin_query/report"
    >
      <div class="d-flex align-items-center">
        <div class="">
          <span class="pr-2">
            <i class="fa fa-th fi-report-element-icon"></i>
          </span>
        </div>
        <div class="pl-1">
          <div class="d-flex">
            <div v-if="fetchingFIs">
              <i class="fa fa-spinner fa-spin"></i>
            </div>
            <div class="d-flex" v-else>
              <h4 class="mb-0">
                <b>{{ dataElementsCount | numberFormat }}</b>
              </h4>
              <div>
                <span class="badge badge-primary rounded py-1 px-2 ml-2"
                  >View Report</span
                >
              </div>
            </div>
          </div>
          <h6 class="mb-0">
            Data {{ dataElementsCount | pluralize('Element') }}
          </h6>
        </div>
      </div>
    </router-link>

    <router-link
      tag="div"
      class="col-sm-4 std-border align-items-center p-2 segment-tab download-tab alert-info"
      to="/technographics/fin_query/download"
    >
      <div class="d-flex align-items-center">
        <div class="">
          <span class="pr-2">
            <i class="fa fi-report-download-icon fa-download fa-3x"></i>
          </span>
        </div>
        <div class="pr-1 pl-2">
          <div v-if="fetchingPriceData">
            <i class="fa fa-spinner fa-spin"></i>
          </div>
          <div v-else>
            <div class="d-flex">
              <h4 class="mb-0" v-if="totalPriceInDollars">
                <b>${{ totalPriceInDollars | numberFormat }}</b>
                <small>
                  <sup>{{ totalPriceInCents }}</sup>
                </small>
              </h4>
              <h4 class="mb-0" v-else>
                <b> - </b>
              </h4>
              <div class="d-flex align-self-center pl-2">
                <span class="badge badge-primary rounded p-1 ml-2 px-2"
                  >Preview Report</span
                >
              </div>
            </div>
          </div>
          <div>{{ purchaseText }}</div>
        </div>
      </div>
    </router-link>
    <div class="col-sm-3"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { elementCount } from '../helpers/utilities.js'
export default {
  name: 'FinQueryTopPanelForMortgageLenders',
  data() {
    return {}
  },
  computed: {
    ...mapState('AdvancedQuery', {
      fiCount: state => state.fiCount,
      fetchingFIs: state => state.fetchingFIs,
      fetchingPriceData: state => state.fetchingPriceData,
      fisWithContactsCount: state => state.fisWithContactsCount
    }),
    ...mapGetters('ReportWriter', ['isFIContactsFiltered']),
    ...mapGetters('AdvancedQuery', [
      'totalPriceInDollars',
      'totalPriceInCents',
      'isMortgageLender'
    ]),
    ...mapState('ReportWriter', {
      loadingContactCount: state => state.loadingContactCount
    }),
    dataElementsCount() {
      return elementCount(this.isMortgageLender)
    },
    purchaseText() {
      if (this.totalPriceInDollars === null) {
        return 'Buy & Download Now'
      }
      return this.totalPriceInDollars > 0 ? 'Buy & Download Now' : 'Download'
    }
  },
  methods: {}
}
</script>
<style lang="scss" src="../scss/advanced.query.scss"></style>
<style lang="scss">
.segment-tab {
  cursor: pointer;
}

.fi-bank-icon {
  font-size: 2.9em;
}

.fi-report-element-icon {
  font-size: 3.1em;
  padding-top: 0.1em;
}

.fi-report-download-icon {
  font-size: 3em;
  padding-top: 0.1em;
}

.query-tab {
  &.active {
    background-color: #f0f3f5;
  }
}

.report-element-tab {
  border-top: 1px solid #c2cfd6;
  border-bottom: 1px solid #c2cfd6;

  &.active {
    background-color: #f0f3f5;
  }
}

.download-tab {
  &.active {
    border-color: #4a7389;
  }
}

.badge {
  color: white;
}
.download {
  color: black;
}
</style>
